import React from "react";
import { ThemeProvider } from "@material-ui/styles";
import TopAppBar from "../containers/TopAppBar";
import { theme } from "../styles/AppStyle";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Footer from "../containers/Footer";

function App() {
  const useStyles = makeStyles(() => ({
    app: {
      background: "#eeeeee",
    },
  }));

  const classes = useStyles();

  return (
    <ThemeProvider theme={theme}>
      <div className={classes.app}>
        <div>
          <TopAppBar />
        </div>

        <div style={{ marginTop: theme.spacing(3) }}>
          <Footer />
        </div>
      </div>
    </ThemeProvider>
  );
}

App.propTypes = {};
export default App;
