import React from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import makeStyles from "@material-ui/core/styles/makeStyles";
import themeColors from "../../styles/themeColors";
import ikinotesLogo from "../../icons/social/pinceau.svg";
import { DisplateLink, EtsyLink, IkinotesLink } from "../common/TechLinks";
import Link from "../common/Link";

function AboutIkinotes() {
  const useStyles = makeStyles((theme) => ({
    about: {
      flexGrow: 1,
      margin: theme.spacing(5),
    },

    link: {
      color: themeColors.accent.main,
      textDecoration: "none",
      fontWeight: "bold",
    },

    gridContent: {
      flex: "1 1 auto !important",
    },
  }));

  const classes = useStyles();
  return (
    <div className={classes.about}>
      <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
        spacing={3}
      >
        <Grid item xs={12} className={classes.gridContent}>
          <a href="https://www.ikinotes.com/">
            <img alt="Ikinotes's logo" width="150" src={ikinotesLogo} />
          </a>
        </Grid>

        <Grid item xs={12} className={classes.gridContent}>
          <Typography>
            Ikinotes,{" "}
            <Link href="https://www.ikinotes.com/beatrice" text="Béatrice" />
            's illustration website using Next.js and hosted on Vercel!
          </Typography>
        </Grid>

        <Grid item xs={12} className={classes.gridContent}>
          <Typography style={{ textAlign: "center" }} variant="h6">
            <IkinotesLink />
            <EtsyLink />
            <DisplateLink />
          </Typography>
        </Grid>
      </Grid>
    </div>
  );
}

AboutIkinotes.propTypes = {};
export default AboutIkinotes;
