import React from "react";
import {
  AntDesignIcon,
  D3Icon,
  DisplateIcon,
  ElasticsearchIcon,
  EtsyIcon,
  FirebaseIcon,
  GitHubIcon,
  GraphQLIcon,
  HerokuIcon,
  IkinotesIcon,
  IntelliJIcon,
  JavaScriptIcon,
  JestIcon,
  MongoDBIcon,
  MUIIcon,
  Neo4jIcon,
  NestIcon,
  NetlifyIcon,
  NextIcon,
  NodeIcon,
  NPMIcon,
  ObservableIcon,
  PrettierIcon,
  ReactIcon,
  RiveIcon,
  SharpIcon,
  SolrIcon,
  StorybookIcon,
  TypescriptIcon,
  VercelIcon,
  ViteIcon,
} from "./TechIcons";
import Link from "./Link";

export function AntDesignLink() {
  return (
    <Link href="https://ant.design/" text="Ant Design" isAnimated={true}>
      <AntDesignIcon />
    </Link>
  );
}

export function D3Link() {
  return (
    <Link href="https://d3js.org/" text="D3.js" isAnimated={true}>
      <D3Icon />
    </Link>
  );
}

export function DisplateLink() {
  return (
    <Link
      href="https://displate.com/Ikinotes?art=659d7a640a7b3"
      text="Displate"
      isAnimated={true}
    >
      <DisplateIcon />
    </Link>
  );
}

export function ElasticsearchLink() {
  return (
    <Link
      href="https://www.elastic.co/elasticsearch/"
      text="Elastic search"
      isAnimated={true}
    >
      <ElasticsearchIcon />
    </Link>
  );
}

export function EtsyLink() {
  return (
    <Link href="https://ikinotesdebea.etsy.com" text="Etsy" isAnimated={true}>
      <EtsyIcon />
    </Link>
  );
}

export function FirebaseLink() {
  return (
    <Link href="https://firebase.google.com/" text="Firebase" isAnimated={true}>
      <FirebaseIcon />
    </Link>
  );
}

export function GitHubLink() {
  return (
    <Link href="https://github.com/" text="GitHub" isAnimated={true}>
      <GitHubIcon />
    </Link>
  );
}

export function GraphQLLink() {
  return (
    <Link href="https://graphql.org/" text="GraphQL" isAnimated={true}>
      <GraphQLIcon />
    </Link>
  );
}

export function HerokuLink() {
  return (
    <Link href="https://www.heroku.com/" text="Heroku" isAnimated={true}>
      <HerokuIcon />
    </Link>
  );
}

export function IkinotesLink() {
  return (
    <Link href="https://www.ikinotes.com/" text="Ikinotes" isAnimated={true}>
      <IkinotesIcon />
    </Link>
  );
}

export function IntelliJLink() {
  return (
    <Link
      href="https://www.jetbrains.com/idea/"
      text="IntelliJ"
      isAnimated={true}
    >
      <IntelliJIcon />
    </Link>
  );
}

export function JavaScriptLink() {
  return (
    <Link
      href="https://www.javascript.com/"
      text="Java Script"
      isAnimated={true}
    >
      <JavaScriptIcon />
    </Link>
  );
}

export function JestLink() {
  return (
    <Link href="https://jestjs.io/" text="Jest.js" isAnimated={true}>
      <JestIcon />
    </Link>
  );
}

export function MongoDBLink() {
  return (
    <Link href="https://www.mongodb.com/" text="MongoDb" isAnimated={true}>
      <MongoDBIcon />
    </Link>
  );
}

export function MUILink() {
  return (
    <Link href="https://mui.com/" text="MUI" isAnimated={true}>
      <MUIIcon />
    </Link>
  );
}

export function Neo4jLink() {
  return (
    <Link href="https://neo4j.com/" text="Neo4j" isAnimated={true}>
      <Neo4jIcon />
    </Link>
  );
}

export function NestLink() {
  return (
    <Link href="https://nestjs.com/" text="Nest.js" isAnimated={true}>
      <NestIcon />
    </Link>
  );
}

export function NetlifyLink() {
  return (
    <Link href="https://www.netlify.com/" text="Netlify" isAnimated={true}>
      <NetlifyIcon />
    </Link>
  );
}

export function NextLink() {
  return (
    <Link href="https://nextjs.org/" text="Next.js" isAnimated={true}>
      <NextIcon />
    </Link>
  );
}

export function NodeLink() {
  return (
    <Link href="https://nextjs.org/" text="Node.js" isAnimated={true}>
      <NodeIcon />
    </Link>
  );
}

export function NPMLink() {
  return (
    <Link href="https://www.npmjs.com/" text="npm" isAnimated={true}>
      <NPMIcon />
    </Link>
  );
}

export function ObservableLink() {
  return (
    <Link href="https://observablehq.com/" text="Observable" isAnimated={true}>
      <ObservableIcon />
    </Link>
  );
}

export function PrettierLink() {
  return (
    <Link href="https://prettier.io/" text="Prettier" isAnimated={true}>
      <PrettierIcon />
    </Link>
  );
}

export function ReactLink() {
  return (
    <Link href="https://reactjs.org/" text="React.js" isAnimated={true}>
      <ReactIcon />
    </Link>
  );
}

export function RiveLink() {
  return (
    <Link href="https://rive.app/" text="Rive" isAnimated={true}>
      <RiveIcon />
    </Link>
  );
}

export function SharpLink() {
  return (
    <Link
      href="https://sharp.pixelplumbing.com/"
      text="Sharp"
      isAnimated={true}
    >
      <SharpIcon />
    </Link>
  );
}

export function SolrLink() {
  return (
    <Link href="https://solr.apache.org/" text="Solr" isAnimated={true}>
      <SolrIcon />
    </Link>
  );
}

export function StorybookLink() {
  return (
    <Link
      href="https://storybook.js.org/"
      text="Storybook.js"
      isAnimated={true}
    >
      <StorybookIcon />
    </Link>
  );
}

export function TypescriptLink() {
  return (
    <Link
      href="https://www.typescriptlang.org/"
      text="Typescript"
      isAnimated={true}
    >
      <TypescriptIcon />
    </Link>
  );
}

export function VercelLink() {
  return (
    <Link href="https://vercel.com/" text="Vercel" isAnimated={true}>
      <VercelIcon />
    </Link>
  );
}

export function ViteLink() {
  return (
    <Link href="https://vitejs.dev/" text="Vite.js" isAnimated={true}>
      <ViteIcon />
    </Link>
  );
}

export function AllLinks() {
  return (
    <>
      <NodeLink />
      <JavaScriptLink />
      <TypescriptLink />
      <NPMLink />
      <ReactLink />
      <RiveLink />
      <NextLink />
      <VercelLink />
      <NestLink />
      <D3Link />
      <PrettierLink />
      <JestLink />
      <GitHubLink />
      <br />
      <IntelliJLink />
      <GraphQLLink />
      <Neo4jLink />
      <SharpLink />
      <FirebaseLink />
      <StorybookLink />
      <AntDesignLink />
      <MUILink />
      <ViteLink />
      <HerokuLink />
      <br />
      <MongoDBLink />
      <NetlifyLink />
      <ObservableLink />
      <SolrLink />
      <ElasticsearchLink />
      <IkinotesLink />
    </>
  );
}
