import Grid from "@material-ui/core/Grid";
import { Typography } from "@material-ui/core";
import React from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import themeColors from "../../styles/themeColors";

function AboutVR() {
  const useStyles = makeStyles((theme) => ({
    about: {
      flexGrow: 1,
      margin: theme.spacing(5),
    },

    link: {
      color: themeColors.accent.main,
      textDecoration: "none",
      fontWeight: "bold",
    },

    gridContent: {
      flex: "1 1 auto !important",
    },
  }));

  const classes = useStyles();
  return (
    <div className={classes.about}>
      <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
        spacing={3}
      >
        <Grid item xs={12} className={classes.gridContent} />

        <Grid item xs={12} className={classes.gridContent}>
          <Typography
            style={{ textAlign: "center", whiteSpace: "pre-line" }}
            variant="h4"
          >
            Video games & VR
          </Typography>
        </Grid>

        <Grid item xs={12} className={classes.gridContent}>
          <Typography style={{ textAlign: "center", whiteSpace: "pre-line" }}>
            Nhogs is also working around video games and VR.
          </Typography>
          <Typography style={{ textAlign: "center", whiteSpace: "pre-line" }}>
            <a
              className={classes.link}
              href="https://www.linkedin.com/in/steeve-ciminera-b3b426153/"
            >
              Steeve
            </a>
            , game developer, is working on asset and much more.
          </Typography>
        </Grid>
      </Grid>
    </div>
  );
}

AboutVR.propTypes = {};
export default AboutVR;
