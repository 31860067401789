import Grid from "@material-ui/core/Grid";
import AboutNhogs from "../containers/AboutNhogs";
import Button from "@material-ui/core/Button";
import themeColors from "../styles/themeColors";
import React from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";

function HomePage({ goToPath }) {
  const useStyles = makeStyles((theme) => ({
    bigButton: {
      backgroundColor: themeColors.accent.main,
      marginTop: theme.spacing(1),
      color: "#ffffff",
      textTransform: "none",
      fontSize: "large",
    },
    gridContent: {
      flex: "1 1 auto !important",
    },
  }));

  const classes = useStyles();

  return (
    <Grid
      container
      direction="column"
      justifyContent="center"
      alignItems="center"
      spacing={2}
    >
      <Grid item xs={12} className={classes.gridContent}>
        <AboutNhogs />
      </Grid>
      <Grid item xs={12} className={classes.gridContent}>
        <Button
          variant="contained"
          className={classes.bigButton}
          size="large"
          onClick={() => goToPath("/projects")}
        >
          Projects
        </Button>
      </Grid>

      <Grid item xs={12} className={classes.gridContent} />
    </Grid>
  );
}

export default HomePage;
