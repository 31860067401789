import createTypography from "@material-ui/core/styles/createTypography";
import "./App.css";
import themeColors from "./themeColors";
import { createTheme } from "@material-ui/core";

export const theme = (() => {
  const palette = {
    ...themeColors,
  };

  const typography = createTypography(palette, {
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif;',
  });

  return createTheme({
    palette,
    typography,
  });
})();
