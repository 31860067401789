import React from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import themeColors from "../../styles/themeColors";
import PropTypes from "prop-types";

function Link({ children, href, text, isAnimated }) {
  const useStyles = makeStyles((theme) => ({
    link: {
      color: themeColors.accent.main,
      textDecoration: "none",
      fontWeight: "bold",
      marginRight: "5px",
    },
  }));

  const classes = useStyles();
  return (
    <>
      <a className={(isAnimated ? "Link " : "") + classes.link} href={href}>
        {children} <span>{text}</span>
      </a>
    </>
  );
}

Link.propTypes = {
  isAnimated: PropTypes.bool,
  href: PropTypes.string,
  text: PropTypes.string,
};
export default Link;
