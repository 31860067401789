import { Route, Switch } from "react-router";
import React from "react";
import HomePage from "../containers/HomePage";
import ProjectPages from "../containers/project-pages/ProjectPages";

function AppRoutes() {
  return (
    <Switch>
      <Route path="/" exact component={() => <HomePage />} />
      <Route path="/projects" exact component={() => <ProjectPages />} />
    </Switch>
  );
}

AppRoutes.propTypes = {};
export default AppRoutes;
