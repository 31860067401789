import React from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import makeStyles from "@material-ui/core/styles/makeStyles";
import themeColors from "../../styles/themeColors";
import observableLogo from "../../icons/social/observablehq.svg";
import Link from "../common/Link";

function AboutObservable() {
  const useStyles = makeStyles((theme) => ({
    about: {
      flexGrow: 1,
      margin: theme.spacing(5),
    },

    link: {
      color: themeColors.accent.main,
      textDecoration: "none",
      fontWeight: "bold",
    },

    gridContent: {
      flex: "1 1 auto !important",
    },
  }));

  const classes = useStyles();
  return (
    <div className={classes.about}>
      <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
        spacing={3}
      >
        <Grid item xs={12} className={classes.gridContent} />

        <Grid item xs={12} className={classes.gridContent}>
          <a href="https://observablehq.com/@nhogs">
            <img alt="observable's logo" width="150" src={observableLogo} />
          </a>
        </Grid>

        <Grid item xs={12} className={classes.gridContent}>
          <Typography>Nhogs is on observable !</Typography>
        </Grid>

        <Grid item xs={12} className={classes.gridContent}>
          <Typography style={{ textAlign: "center", whiteSpace: "pre-line" }}>
            <Link href="https://observablehq.com/@nhogs" text="Nhogs" />
            <Link href="https://observablehq.com/@popotojs" text="Frederic" />
            <Link href="https://observablehq.com/@pernifloss" text="Lary" />
            <Link href="https://observablehq.com/@tarte0" text="Steeve" />
            <Link href="https://observablehq.com/@zechasault" text="Zack" />
          </Typography>
        </Grid>
      </Grid>
    </div>
  );
}

AboutObservable.propTypes = {};
export default AboutObservable;
