import React from "react";
import themeColors from "../styles/themeColors";
import Grid from "@material-ui/core/Grid";
import makeStyles from "@material-ui/core/styles/makeStyles";
import linkedinLogo from "../icons/social/linkedin.svg";
import githubLogo from "../icons/social/github-light.svg";
import twitterLogo from "../icons/social/x.svg";
import observableLogo from "../icons/social/observablehq-light.svg";
import { Typography } from "@material-ui/core";
import { theme } from "../styles/AppStyle";

function Footer() {
  const useStyles = makeStyles({
    bigAvatar: {
      margin: 10,
      width: 35,
    },
  });

  const classes = useStyles();

  return (
    <footer style={{ background: themeColors.primary.main }}>
      <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
        spacing={2}
        style={{ marginTop: theme.spacing(1) }}
      >
        <Grid item xs={12}>
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
            spacing={2}
            style={{ marginTop: theme.spacing(1) }}
          >
            <a href="https://www.linkedin.com/company/nhogs-interactive/">
              <img
                alt="linkedin"
                src={linkedinLogo}
                className={classes.bigAvatar}
              />
            </a>
            <a href="https://github.com/Nhogs">
              <img
                alt="github"
                src={githubLogo}
                className={classes.bigAvatar}
              />
            </a>
            <a href="https://twitter.com/FredCiminera">
              <img
                alt="twitter"
                src={twitterLogo}
                className={classes.bigAvatar}
              />
            </a>
            <a href="https://observablehq.com/@nhogs">
              <img
                alt="observable"
                src={observableLogo}
                className={classes.bigAvatar}
              />
            </a>
          </Grid>

          <Typography
            color={"secondary"}
            style={{ textAlign: "center", marginTop: 20 }}
          >
            Copyright © 2017-2024 NHOGS Interactive
          </Typography>
        </Grid>
      </Grid>
    </footer>
  );
}

Footer.propTypes = {};
export default Footer;
