import React from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import makeStyles from "@material-ui/core/styles/makeStyles";
import themeColors from "../../styles/themeColors";
import Link from "../common/Link";

function About3D() {
  const useStyles = makeStyles((theme) => ({
    about: {
      flexGrow: 1,
      margin: theme.spacing(5),
    },

    link: {
      color: themeColors.accent.main,
      textDecoration: "none",
      fontWeight: "bold",
    },

    gridContent: {
      flex: "1 1 auto !important",
    },
  }));

  const classes = useStyles();
  return (
    <div className={classes.about}>
      <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
        spacing={3}
      >
        <Grid item xs={12} className={classes.gridContent} />

        <Grid item xs={12} className={classes.gridContent}>
          <Typography
            style={{ textAlign: "center", whiteSpace: "pre-line" }}
            variant="h4"
          >
            3D modeling
          </Typography>
        </Grid>

        <Grid item xs={12} className={classes.gridContent}>
          <iframe
            title="A 3D model"
            width="100%"
            height="300"
            src="https://sketchfab.com/models/1f06c8d58c0941c8897c896ef4b78378/embed"
            frameBorder="0"
            allow="autoplay; fullscreen; vr"
            mozallowfullscreen="true"
            webkitallowfullscreen="true"
          />
          <Typography>
            Nhogs team members have various 3D models on Sketchfab
          </Typography>
        </Grid>

        <Grid item xs={12} className={classes.gridContent}>
          <Typography style={{ textAlign: "center", whiteSpace: "pre-line" }}>
            <Link
              href="https://sketchfab.com/larycim"
              text="Lary's 3D models"
            />
            <Link
              href="https://sketchfab.com/Stev_3D"
              text="Steeve's 3D models"
            />
            <Link
              href="https://sketchfab.com/Zacxophone"
              text="Zack's 3D models"
            />
          </Typography>
        </Grid>
      </Grid>
    </div>
  );
}

About3D.propTypes = {};
export default About3D;
