import React from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import makeStyles from "@material-ui/core/styles/makeStyles";
import themeColors from "../../styles/themeColors";
import gitHubLogo from "../../icons/social/github.svg";
import Link from "../common/Link";

function AboutGitHub() {
  const useStyles = makeStyles((theme) => ({
    about: {
      flexGrow: 1,
      margin: theme.spacing(5),
    },

    link: {
      color: themeColors.accent.main,
      textDecoration: "none",
      fontWeight: "bold",
    },

    gridContent: {
      flex: "1 1 auto !important",
    },
  }));

  const classes = useStyles();
  return (
    <div className={classes.about}>
      <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
        spacing={3}
      >
        <Grid item xs={12} className={classes.gridContent} />

        <Grid item xs={12} className={classes.gridContent}>
          <a href="https://github.com/nhogs">
            <img alt="GitHub's logo" width="150" src={gitHubLogo} />
          </a>
        </Grid>

        <Grid item xs={12} className={classes.gridContent}>
          <Typography>
            Check out Nhogs' <em>Open Source</em> projects on GitHub&nbsp;!
          </Typography>
        </Grid>

        <Grid item xs={12} className={classes.gridContent}>
          <Typography style={{ textAlign: "center", whiteSpace: "pre-line" }}>
            <Link
              href="https://github.com/Nhogs/nestjs-neo4j"
              text="Nest.js Neo4j"
            ></Link>
            <Link
              href="https://github.com/Nhogs/nestjs-firebase"
              text="Nest.js Firebase"
            ></Link>
            <Link
              href="https://github.com/Nhogs/nestjs-pinecone"
              text="Nest.js Pinecone"
            ></Link>
            <Link
              href="https://github.com/Nhogs/popoto"
              text="Popoto.js"
            ></Link>
            <Link
              href="https://github.com/Nhogs/popoto-examples"
              text="Popoto.js Examples"
            ></Link>
          </Typography>
        </Grid>
      </Grid>
    </div>
  );
}

AboutGitHub.propTypes = {};
export default AboutGitHub;
